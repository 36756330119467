import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import {
  Alert,
  Box,
  Button,
  Link,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { X as CloseIcon } from 'react-feather';

import { SlateDocument } from '@pv/common/components';
import { moneyPrint } from '@pv/common/utils';
import { NavigationBar } from '../NavigationBar';
import { StepCounter } from '../StepCounter';
import { FooterBar } from '../../../components/FooterBar';
import {
  TwoColumnLayout,
  ColumnContainer,
  PageContainer,
} from '../styledComponents';
import {
  VENUE_BOOKINGS_QUERY,
  SPACE_BOOKINGS_QUERY,
  CONTRACT_QUERY,
} from '../graphql';
import { Section, SectionHeader, PinkInfoBox } from './styledComponents';
import { CheckoutSummary } from './CheckoutSummary';
import { formatPercentageRate } from '../utils';
export const ExpressBookCheckoutForm = () => {
  const { venueSlug, spaceSlug, eventSlug } = useParams();
  const [signature, setSignature] = React.useState('');
  const [showPolicyModal, setShowPolicyModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const { data: venueData } = useQuery(VENUE_BOOKINGS_QUERY, {
    variables: { venueSlug },
    skip: !venueSlug,
  });

  const venue = venueData?.marketplaceVenue;

  const { data: spaceData } = useQuery(SPACE_BOOKINGS_QUERY, {
    variables: { spaceSlug },
    skip: !spaceSlug,
  });

  const space = spaceData?.marketplaceSpace;

  const { data: contractData } = useQuery(CONTRACT_QUERY, {
    variables: { eventSlug },
    skip: !eventSlug,
    onCompleted: (data: any) => {
      if (data?.marketplaceEvent?.expressBookRequest?.status !== 'incomplete') {
        navigate(
          `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
        );
      }
    },
    onError: (error) => {
      console.error(error);
      navigate(
        `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}`,
      );
    },
  });

  const event = contractData?.marketplaceEvent;
  const contract = event?.contract;

  const venuePolicy = contract?.venuePolicy?.body;

  const getDepositPreamble = () => {
    const depositRate = contract?.depositRate;
    if (depositRate) {
      return `A ${formatPercentageRate(depositRate)} deposit of`;
    }

    return 'A deposit of';
  };

  const depositAmount = contract?.totals?.deposit || 0;

  const sendRequest = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    if (!signature) {
      setErrorMessage('Please sign the agreement');
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success?signature=${signature}`,
      },
    });

    if (error && error.message) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      setErrorMessage('');
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Box>
      <NavigationBar />
      <PageContainer>
        <StepCounter activeStepIndex={2} />
        <TwoColumnLayout container={true} spacing={2}>
          <ColumnContainer item={true} xs={12} sm={6}>
            <form onSubmit={sendRequest}>
              <Stack spacing={2}>
                <Section>
                  <SectionHeader>Pay Deposit</SectionHeader>
                  <Typography variant="body1">
                    Add a payment method. {getDepositPreamble()}
                    <strong> {moneyPrint(depositAmount / 100)} </strong>
                    will be charged if the venue accepts your event.
                  </Typography>
                  <PaymentElement />
                </Section>
                <Divider />
                <Section>
                  <SectionHeader>Policies</SectionHeader>
                  <Box
                    sx={{
                      maxHeight: '192px',
                      overflowY: 'hidden',
                    }}
                  >
                    {venuePolicy && <SlateDocument source={venuePolicy} />}
                  </Box>
                  <span>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setShowPolicyModal(true)}
                    >
                      View More
                    </Link>
                  </span>
                </Section>
                <Divider />
                <Section>
                  <SectionHeader>Sign Proposal</SectionHeader>
                  <PinkInfoBox>
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <img src="/clock-cal.svg" alt="" />
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        <strong style={{ fontWeight: 500 }}>
                          Your reservation won’t be confirmed until the Venue
                          accepts your request (within 24 hours).
                        </strong>{' '}
                        You won’t be charged until then.
                      </Typography>
                    </Box>
                  </PinkInfoBox>
                  <TextField
                    InputProps={{
                      style: { fontFamily: 'Dancing Script', fontSize: 30 },
                      startAdornment: (
                        <InputAdornment position="start">X</InputAdornment>
                      ),
                    }}
                    placeholder="Sign Here..."
                    variant="standard"
                    required
                    name="signature"
                    aria-label="Signature"
                    value={signature}
                    onChange={(e) => setSignature(e.target.value)}
                  />
                  <Typography variant="body2" sx={{}}>
                    By signing the agreement, you agree to the{' '}
                    <span>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => setShowPolicyModal(true)}
                        sx={{
                          verticalAlign: 'baseline',
                        }}
                      >
                        Venue's Policies
                      </Link>
                    </span>
                    .
                  </Typography>
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{
                      height: '56px',
                      maxWidth: {
                        sm: '300px',
                        xs: '100%',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '26px',
                        color: 'white',
                      }}
                    >
                      Send Request
                    </Typography>
                  </Button>
                </Section>
              </Stack>
            </form>
          </ColumnContainer>
          <ColumnContainer item={true} xs={12} sm={6}>
            <CheckoutSummary
              venue={venue}
              space={space}
              event={event}
              contract={contract}
            />
          </ColumnContainer>
        </TwoColumnLayout>
        <FooterBar />
      </PageContainer>
      <Dialog open={showPolicyModal} onClose={() => setShowPolicyModal(false)}>
        <DialogTitle>
          Policies
          <Button
            onClick={() => setShowPolicyModal(false)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <SlateDocument source={venuePolicy} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
